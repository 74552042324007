// @font-face {
//     font-family: 'Aref Ruqaa';
//     // font-style: normal;
//     // font-weight: 400;
//     // font-display: swap;
//     src: url(/assets/fonts/ArefRuqaa-Regular.ttf);
//     // unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
//   }
  @font-face {
    font-family: 'Tajawal';
    // font-style: normal;
    // font-weight: 200;
    // font-display: swap;
    src: url(/assets/fonts/Tajawal-Regular.ttf);
    // unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
  }
  @font-face {
    font-family: 'Tajawal Bold';
    // font-style: normal;
    // font-weight: 200;
    // font-display: swap;
    src: url(/assets/fonts/Tajawal-Bold.ttf);
    // unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
  }
$rtl: true !default;
html{
    direction: rtl;
}
$family-sans-serif: 'Tajawal Bold','Tajawal', sans-serif;
@import './bluma_import.scss';